import React, { useState } from "react";
import { toast } from "react-toastify";
//SERVICIOS
import fglobales from "../../services/fglobales";

function ChargeImgPeter({ dataUser }) {
  const [submitted, setSubmitted] = useState(false);
  console.log("🚀 ~ imgPeter ~ submitted:", submitted);
  const [imgFachada, setImgFachada] = useState(null);
  const [imgFachadaSrc, setImgFachadaSrc] = useState(
    dataUser.imgPeter ? dataUser.imgPeter : null
  );

  const [viewBtn, setViewBtn] = useState("0");

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const imgPremio = files[0];

    const fileTypes = ["image/jpeg", "image/png"];
    if (!fileTypes.includes(imgPremio.type)) {
      toast.error("Subir fotos .jpeg o .png");
      return;
    }

    if (imgPremio.size > 20 * 1024 * 1024) {
      toast.error("Imagen demasiado grande:", imgPremio.size);
      return;
    }

    setImgFachada(imgPremio);
    setImgFachadaSrc(URL.createObjectURL(imgPremio));
  };

  const updateUserImg = async (dataImg, campoImg, view) => {
    setSubmitted(true);
    const addSucursalImg = await fglobales.addSucursalImg(
      "usersClients",
      dataUser,
      dataImg,
      campoImg
    );

    toast.success(addSucursalImg.message);
    setSubmitted(false);
    setViewBtn(view);
  };

  return (
    <>
      <div className='col-12 md:col-10 md:col-offset-1 mt-4 contentForm'>
        <div className='grid'>
          <div className='col-12 md:col-10 md:col-offset-1'>
            <h2 className='mt-0 text-center'>
              <b>RETO MR. PITER</b>
            </h2>
          </div>

          <div className='col-12 md:col-10 md:col-offset-1'>
            <div className='grid'>
              {/*Fachada*/}
              <div className='col-12'>
                <div className='docImgL'>
                  <div className='boxImg'>
                    {imgFachadaSrc && (
                      <img
                        src={imgFachadaSrc}
                        alt='Captura'
                      />
                    )}
                  </div>

                  {dataUser.imgPeter || viewBtn === "1" ? (
                    <div className='col-12'>
                      <p className='adjuntoProfile'>Evidencia</p>
                    </div>
                  ) : (
                    <div className='boxInfo'>
                      <div className='grid'>
                        {imgFachadaSrc ? (
                          <>
                            <div className='col-6'>
                              <label>
                                <p className='adjuntoProfile'>
                                  Editar <i className='pi pi-pencil'></i>
                                </p>
                                <input
                                  type='file'
                                  accept='image/jpeg, image/png'
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                              </label>
                            </div>

                            <div className='col-6'>
                              <p
                                className='adjuntoProfile'
                                onClick={() => {
                                  updateUserImg(imgFachada, "imgPeter", "1");
                                }}>
                                Subir <i className='pi pi-cloud-upload'></i>
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className='col-12'>
                            <br />
                            <label className='p-button-brilla p-button-sucursal'>
                              Cargar captura
                              <input
                                type='file'
                                accept='image/jpeg, image/png'
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <p className='sucursalCum text-center'>
              <b>Sube tu evidencia</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChargeImgPeter;
